<template>
    <b-sidebar
      id="add-new-attendance-sidebar"
      :visible="isAddNewAttendanceSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-add-new-attendance-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ attendanceLocal.id ? 'Update': 'Add' }} Attendance
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            
            <!-- Member -->
            <validation-provider
              #default="validationContext"
              name="member"
              rules="required"
            >
              <b-form-group
                  label="Select Member"
                  label-for="member"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="attendanceLocal.member"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="getAllMembers.map(m=>({ member: m.fullName, memID: m.memID, contact1: m.contact1 }))"
                    label="member"
                    :close-on-select="true"
                    input-id="member"
                  />
                  <template #option="{ memID, member }">
                    <!-- <b-avatar
                      size="sm"
                      :src="avatar"
                    /> -->
                    <span> {{ memID }}</span>
                    <span class="ml-50 align-middle"> {{ member }}</span>
                  </template>

                  <template #selected-option="{  member }">
                    <!-- <b-avatar
                      size="sm"
                      class="border border-white"
                      :src="avatar"
                    /> -->
                    <span class="ml-50 align-middle"> {{ member }}</span>
                  </template>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>


            <!-- Date -->
            <b-form-group
              label="Date Time"
              label-for="date"
            >
              <flat-pickr
                label="Date"
                label-for="date"
                class="form-control"
                v-model="attendanceLocal.date"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
              />
            </b-form-group>


            <!-- Mission -->
            <validation-provider
              #default="validationContext"
              name="Service"
              rules="required"
            >
              <b-form-group
                  label="Service"
                  label-for="service"
                  :state="getValidationState(validationContext)"
                >
                <v-select
                    v-model="attendanceLocal.service"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="serviceOptions"
                    label="label"
                    :reduce="ser => ser.value"
                    :close-on-select="true"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            

            <!-- Note -->
            <b-form-group
                label="Note"
                label-for="note"
              >
                <b-form-textarea
                  id="note"
                  v-model="attendanceLocal.note"
                  trim
                  placeholder="Note"
                >
                </b-form-textarea>
            </b-form-group>
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormDatepicker, BFormTextarea, BFormInvalidFeedback, BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref,toRefs } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import useMembersList from '../../membership/members-list/useMemberList'
  import useAttendancesListAddNew from './useAttendanceListAddNew'
  import flatPickr from 'vue-flatpickr-component'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormDatepicker,
      BFormTextarea,
      BFormInvalidFeedback,
      BButton,
      vSelect,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      flatPickr
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewAttendanceSidebarActive',
      event: 'update:is-add-new-attendance-sidebar-active',
    },
    props: {
      isAddNewAttendanceSidebarActive: {
        type: Boolean,
        required: true,
      },
      attendanceData: {
        type: Object
      },
      clearAttendanceData: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries
      }
    },
    setup(props, { emit }) {
      
      const clearFormData = ref(null)

      const { 
        attendanceLocal,
        resetAttendanceLocal,
        onSubmit,
        serviceOptions
      
      } = useAttendancesListAddNew(toRefs(props), clearFormData, emit)

      const { getAllMembers } = useMembersList()
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
      } = formValidation(resetAttendanceLocal, props.clearAttendanceData)

      clearFormData.value = clearForm
  
      return {
        attendanceLocal,
        onSubmit,
        serviceOptions,
        resetForm,
        refFormObserver,
        getValidationState,
        getAllMembers
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  #add-new-attendance-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  