<template>

    <!-- Table Container Card -->
    <b-card
      no-body
    >
    <attendance-list-add-new
      v-model="isAddNewAttendanceSidebarActive"
      :attendance-data="attendanceData"
      :clear-attendance-data="clearAttendanceData"
      @add-attendance="addAttendance"
      @update-attendance="updateAttendance"
    />

      <div class="m-2">
  
        <!-- Table Top -->
        <b-row>
  
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
                variant="primary"
                @click="isAddNewAttendanceSidebarActive = true"
                class="mr-1"
            >
                Add Record
            </b-button>

            <b-button
              variant="outline-primary"
              class="mr-1"
              @click="genAttendanceReport"
            >
              Export to *.Excel
            </b-button>
          </b-col>
  
          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-datepicker
                v-model="fromDate"
                placeholder="Date From"
                class="mr-1"
                today-button
                reset-button
                close-button
              ></b-form-datepicker>
              
              <b-form-datepicker
                v-model="toDate"
                placeholder="Date To"
                class="mr-1"
                today-button
                reset-button
                close-button
              ></b-form-datepicker>

              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <v-select
                v-model="rangeFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rangeOptions"
                :reduce="range => range.value"
                class="invoice-filter-select mr-1"
                placeholder="Select Range"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
              <v-select
                v-model="serviceFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="serviceOptions"
                :reduce="service => service.value"
                class="invoice-filter-select"
                placeholder="Select Service"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
  
      </div>
  
      <b-table
        ref="refAttendanceListTable"
        :items="getAttendances"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
  
        <!-- Column: Id -->
        <template #cell(id)="data">
          <!-- <b-link
            :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
            class="font-weight-bold"
          > -->
            <!-- #{{ data.value }} -->
            {{ data.index+1 }}
          <!-- </b-link> -->
        </template>

        <template #cell(type)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveTypeRoleIcon(data.item.type)"
              size="18"
              class="mr-50"
              :class="`text-primary`"
            />
            <span class="font-weight-bold align-text-top text-capitalize">{{ resolveTypeVariant(data.item.type) }} </span>
          </div>

          </template>
          <template #cell(_date)="data">
          <div class="text-nowrap">
            <span class="font-weight-bold align-text-top text-capitalize">{{ data.item._date }}</span>
          </div>
          </template>

          
          <template #cell(memID)="data">
              {{ data.item.memID ? '#'+data.item.memID  : ''}}
          </template>

          <template #cell(member)="data">
            <b-link
              v-if="data.item._member !== ''"
              :href="`tel:${data.item.member.contact1.countryCode+data.item.member.contact1.tel}`"
              class="font-weight-bold"
              title="Call"
            >
              {{ data.item._member  }}
            </b-link>
          </template>

          <template #cell(service)="data">
          <div class="text-nowrap">
            <b-badge
              pill
              :variant="`light-${resolveAttendanceStatusVariant(data.item.service)}`"
              class="text-capitalize"
            >
              {{ data.item.service }}
            </b-badge>
          </div>
        </template>

        <template #cell(totalAmount)="data">
          <span
              class="font-weight-bolder  text-primary"
            >
              {{ data.item.totalAmount.toFixed(2) }}
          </span>
        </template>

        <template #cell(count)="data">
          <b-link
            class="font-weight-bold"
            :to="{ name: 'app-attendance-list', params: {  type: data.item.type, date: new Date(data.item.attendanceDate).toGMTString().substring(5,16), service: data.item.service, } }"
          >
            {{ data.item.count  }} attendances
          </b-link>
        </template>
  
        <!-- Column: Actions -->
        <template #cell(actions)="data">
  
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class=""
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
            
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="editAttendance(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="delAttendance(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
  
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
  
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries </span>
            
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
  
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAttendances"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
  
          </b-col>
  
        </b-row>
      </div>
    </b-card>
  
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BFormTextarea, BFormDatepicker, BFormTimepicker, BForm, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BAlert
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { ref, onUnmounted, computed } from '@vue/composition-api'
  import store from '@/store'
  import useAttendanceList from './useAttendanceList'
  import flatPickr from 'vue-flatpickr-component'
  
  import attendanceStoreModule from '../attendanceStoreModule'
  import router from '@/router'
  import AttendanceListAddNew from '../attendance-add-new/AttendanceListAddNew.vue'
  import Ripple from 'vue-ripple-directive'
  import memberStoreModule from '../../membership/memberStoreModule'
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BFormTextarea,
      BFormDatepicker,
      BFormTimepicker,
      BForm,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BFormGroup,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BTooltip,
      BAlert,
      flatPickr,
      vSelect,
      AttendanceListAddNew
    },
    directives: {
        Ripple,
    },
    data() {
      return {
        router
      }
    },
    setup() {
  
      const ACCOUNT_RECEIPT_STORE_MODULE_NAME = 'app-attendance'
      const MEMBER_APP_STORE_MODULE_NAME = 'app-member'
  
      // Register module
      if (!store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME, attendanceStoreModule)
      if (!store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.registerModule(MEMBER_APP_STORE_MODULE_NAME, memberStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)
        if (store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_APP_STORE_MODULE_NAME)
      })

      const serviceOptions = [
        { label: 'First', value: 'first' },
        { label: 'Second', value: 'second' },
        { label: 'One', value: 'one' },
        { label: 'Third', value: 'third' },
        { label: 'Fourth', value: 'fourth' },
        { label: 'Fifth', value: 'fifth' },
      ]

  
      const rangeOptions = [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        // { label: 'This Week', value: 'this week' },
        // { label: 'Previous Week', value: 'previous week' },
        { label: 'This Month', value: 'this month' },
        { label: 'Previous Month', value: 'previous month' },
        { label: 'This Year', value: 'this year' },
        { label: 'Previous Year', value: 'previous year' }
      ]
  
      const {
        fetchAttendances,
        approveAttendances,
        getAttendances,
        tableColumns,
        perPage,
        currentPage,
        totalAttendances,
        dataMeta,
        perPageOptions,
        searchQuery,
        fromDate,
        toDate,
        sortBy,
        isSortDirDesc,
        refAttendanceListTable,
  
        rangeFilter,
        serviceFilter,
  
        refetchData,
        resolveAttendanceStatusVariant,
        resolveTypeVariant,
        resolveTypeRoleIcon,
        isAddNewAttendanceSidebarActive,
        addAttendance,
        updateAttendance,
        delAttendance,
        clearAttendanceData,
        editAttendance,
        attendanceData,
        fetchMembers,
        genAttendanceReport
        
      } = useAttendanceList()
  
      fetchAttendances()

      fetchMembers()
      
      
      return {
        approveAttendances,
        getAttendances,
        tableColumns,
        perPage,
        currentPage,
        totalAttendances,
        dataMeta,
        perPageOptions,
        searchQuery,
        fromDate,
        toDate,
        sortBy,
        isSortDirDesc,
        refAttendanceListTable,
        serviceOptions,
  
        rangeFilter,
        serviceFilter,
        refetchData,
        rangeOptions,
        resolveAttendanceStatusVariant,
        resolveTypeVariant,
        resolveTypeRoleIcon,
        isAddNewAttendanceSidebarActive,
        addAttendance,
        updateAttendance,
        delAttendance,
        clearAttendanceData,
        editAttendance,
        attendanceData,
        genAttendanceReport
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  