import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAttendancesList() {
  // Use toast
  const toast = useToast()

  const refAttendanceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'memID', label: 'mem ID', sortable: true },
    { key: 'member', sortable: false },
    { key: 'service', label: 'service', sortable: true },
    { key: 'note', label: 'note', sortable: false },
    { key: '_date', label: 'Date', sortable: true },
    { key: '_createdAt', label: 'created', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalAttendances = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const fromDate = ref('')
  const toDate = ref('')
  const sortBy = ref('date')
  const isSortDirDesc = ref(true)
  const rangeFilter = ref(null)
  const serviceFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refAttendanceListTable.value ? refAttendanceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAttendances.value,
    }
  })

  const getAttendances = computed(() => {
    const response =  store.getters['app-attendance/getAttendances']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      range: rangeFilter.value,
      service: serviceFilter.value,
      from: fromDate.value,
      to: toDate.value,
    })

    const { attendances, total } = response
    totalAttendances.value = total

    return attendances
  })

  const refetchData = () => {
    refAttendanceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, rangeFilter, serviceFilter, fromDate, toDate], () => {
    refetchData()
  })

  const fetchAttendances = (ctx, callback) => {
    store
      .dispatch('app-attendance/fetchAttendances')
      .then(() => {
        // const { Attendances, total } = response.data

        // callback(Attendances)
        
        // totalAttendances.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Attendances' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const blankAttendanceData = {
    date: null,
    service: '',
    member: null,
    note: '',
  }

  const attendanceData = ref(JSON.parse(JSON.stringify(blankAttendanceData)))

  const clearAttendanceData = () => {
    attendanceData.value = JSON.parse(JSON.stringify(blankAttendanceData))
  }
  const editAttendance = (attendanceEvent) => {
    
    attendanceData.value = JSON.parse(JSON.stringify(Object.assign(attendanceEvent)))
    // const { attendanceData } = data
    // emit('update:is-add-new-attendance-sidebar-active', true)
    isAddNewAttendanceSidebarActive.value = true
    // console.log(attendanceEvent)

    attendanceData.value = grabDataFromEditAttendance(attendanceEvent)
  }

  const grabDataFromEditAttendance = attendanceData => {

    const {
      id,
      date,
      service,
      member,
      note
    } = attendanceData

    return {
      id,
      date,
      service,
      member,
      note
    }
  }

  // ------------------------------------------------
  // addAttendance
  // ------------------------------------------------
  const addAttendance = eventData => {
    store.dispatch('app-attendance/addAttendance', { attendance: eventData }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchAttendances()

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: response.data,
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
    }).catch(error=> {

      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    })
  }

  // ------------------------------------------------
  // updateAttendance
  // ------------------------------------------------
  const updateAttendance = eventData => {
    
    store.dispatch('app-attendance/updateAttendance', { attendance: eventData }).then(response => {
      
      fetchAttendances()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      isAddNewAttendanceSidebarActive.value = false
    }).catch(error=> {

      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

    })
  }

  const delAttendance = eventData => {
    store.dispatch('app-attendance/delAttendance', { attendance: eventData }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchAttendances()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }

  const fetchMembers = (ctx, callback) => {
    store
      .dispatch('app-member/fetchMembers')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching payments list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  const genAttendanceReport =  () => {
    //
    store.dispatch('app-attendance/genAttendanceReport').then(() => {
      // eslint-disable-next-line no-use-before-define
      // fetchReceipts()

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: response.data,
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
    })
  }


  const isAddNewAttendanceSidebarActive = ref(false)

  const resolveAttendanceStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'approved') return 'success'
    if (status === 'cancelled') return 'danger'
    return 'primary'
  }

  const resolveRangeVariant = type => {
    if (type === 'today') return 'Today'
    if (type === 'yesterday') return 'Yesterday'
    // if (type === 'this week') return 'This Week'
    // if (type === 'previous week') return 'Previous Week'
    if (type === 'this month') return 'This Month'
    if (type === 'previous month') return 'Previous Month'
    if (type === 'this year') return 'This Year'
    if (type === 'previous year') return 'Previous Year'
  }

  const resolveTypeVariant = type => {
    if (type === 'offering') return 'Offering'
    if (type === 'tithe') return 'Tithe'
    if (type === 'welfare') return 'Welfare'
    if (type === 'harvest') return 'Harvest'
    if (type === 'special offering') return 'Special Offering'
    if (type === 'thanksgiving') return 'Thanksgiving'
    if (type === 'others') return 'Others'
  }

  const resolveTypeRoleIcon = role => {
    if (role === 'offering') return 'ShieldIcon'
    if (role === 'tithe') return 'PocketIcon'
    if (role === 'welfare') return 'AttendancesIcon'
    if (role === 'harvest') return 'ArchiveIcon'
    if (role === 'special offering') return 'MailIcon'
    if (role === 'thanksgiving') return 'HeartIcon'
    if (role === 'others') return 'CreditCardIcon'
    return ''
  }

  return {
    // onSubmit,
    getAttendances,
    fetchAttendances,
    tableColumns,
    perPage,
    currentPage,
    totalAttendances,
    dataMeta,
    perPageOptions,
    searchQuery,
    fromDate,
    toDate,
    sortBy,
    isSortDirDesc,
    refAttendanceListTable,

    rangeFilter,
    serviceFilter,
    resolveRangeVariant,
    resolveAttendanceStatusVariant,
    refetchData,
    resolveTypeVariant,
    resolveTypeRoleIcon,
    isAddNewAttendanceSidebarActive,
    addAttendance,
    updateAttendance,
    delAttendance,
    clearAttendanceData,
    editAttendance,
    attendanceData,
    fetchMembers,
    genAttendanceReport
  }
}
