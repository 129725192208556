import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function useAttendanceListAddNew(props, clearForm, emit) {
  // ------------------------------------------------
  // attendanceLocal
  // ------------------------------------------------
  const attendanceLocal = ref(JSON.parse(JSON.stringify(props.attendanceData.value)))
  const resetAttendanceLocal = () => {
    attendanceLocal.value = JSON.parse(JSON.stringify(props.attendanceData.value))
  }
  watch(props.attendanceData, () => {
    resetAttendanceLocal()
  })

  // ------------------------------------------------
  // isAddNewAttendanceSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isAddNewAttendanceSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
//   const calendarOptions = computed(() => store.state.calendar.calendarOptions)

  const onSubmit = () => {
    const attendanceData = JSON.parse(JSON.stringify(attendanceLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.attendanceData.value.id) emit('update-attendance', attendanceData.value)
    else emit('add-attendance', attendanceData.value)

    // Close sidebar
    // emit('update:is-add-new-attendance-sidebar-active', false)
  }


  const serviceOptions = [
    { label: 'First', value: 'first' },
    { label: 'Second', value: 'second' },
    { label: 'One', value: 'one' },
    { label: 'Third', value: 'third' },
    { label: 'Fourth', value: 'fourth' },
    { label: 'Fifth', value: 'fifth' },
  ]

//   const attendancesOptions = computed(() => store.state.calendar.attendances)

  /* eslint-enable global-require */

  return {
    attendanceLocal,
    resetAttendanceLocal,
    // calendarOptions,

    serviceOptions,
    onSubmit,
  }
}
