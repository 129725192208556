import axios from '@/libs/axios'
// '@/libs/axios'
const userData = JSON.parse(localStorage.getItem('userData'))
import { paginateArray, sortCompare } from '@/@fake-db/utils'
import moment from 'moment'
import { saveAs } from 'file-saver';

export default {
  namespaced: true,
  state: {
    attendances: [],
    total: 0,
  },
  getters: {
    getAttendances: (state) => (params) => {

      const { q = '', perPage = 10, page = 1, sortBy = 'date', sortDesc = true, range = null, service = null  } = params

      const getRange = (range) => {
        switch (range) {
            case "today":

              return new Date().toISOString().substring(0,10)
            
            break;

            case "yesterday":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'days').toDate().toISOString().substring(0,10)
            
            break;

            case "this month":

              return new Date().toISOString().substring(0,7)
            
            break;

            case "previous month":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'months').toDate().toISOString().substring(0,7)

            break;

            case "this year":

              return new Date().toISOString().substring(0,4)
            
            break;

            case "previous year":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'years').toDate().toISOString().substring(0,4)
            
            break;
        
          default:
            break;
        }
      }

      const queryLowered = q.toLowerCase()
      const filteredData = state.attendances.filter(
        attendance =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (attendance._member.toLowerCase().includes(queryLowered) ||
          attendance.memID.toLowerCase().includes(queryLowered) ||
          attendance.note.toLowerCase().includes(queryLowered) ||
          attendance._date.toLowerCase().includes(queryLowered)) 
          &&
          attendance.date.includes(getRange(range) || attendance.date) &&
          attendance.service === (service || attendance.service),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      return {
        attendances: paginateArray(sortedData, perPage, page),
        total: filteredData.length
      }
    }
  },
  mutations: {
    updateAttendances:  (state, attendances) => {
      //
      state.attendances = attendances
      state.total = attendances.length
    },
    updateAttendance: (state, attendance) => {
      //
      state.attendance = attendance
    }
  },
  actions: {
    fetchAttendances({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/attendances/${userData.church}`)
          .then(response => { 
            
            //
            commit('updateAttendances', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    fetchAttendance(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/attendance/${userData.church}/${id}`)
          .then(response => { 

            //
            commit('updateAttendance', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    addAttendance(ctx, { attendance }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/attendance', { ...attendance, church: userData.church, user: userData.fullName })
          // .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAttendance(ctx, { attendance }) {
      console.log(attendance)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/attendance/${userData.church}/${attendance.id}`, { ...attendance })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delAttendance(ctx, { attendance }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/attendance/${userData.church}/${attendance.id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    genAttendanceReport(ctx, memberData) {
      return new Promise((resolve, reject) => {

        const fileName = 'Attendance_Report_'+(new Date().toISOString().substring(0,10))+'_'+new Date().getTime()

          axios.post(`/attendance/report/${userData.church}`, {
            fileName: fileName
          }, {
              responseType: 'blob'
          }).then((response) => {
              //
              saveAs(response.data, fileName);
          });
      })
    },
  },
}
